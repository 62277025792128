import Api from '@/api/base'
import { MACHINE_CONFIG, MACHINE_CONFIG_2 } from '@/api/resource'

export default {
  findByLocationId(id) {
    return Api().get(MACHINE_CONFIG, {
      params: {
        locationId: id,
      },
    })
  },
  fetch() {
    return Api().get(MACHINE_CONFIG_2, {
      params: {
        locationId: 1,
      },
    })
  },
  save(serialNumber, ipAddress, isCartonPackingMachine, isActive, locationId) {
    // const formData = new FormData()
    // formData.append('serialNumber', serialNumber)
    // formData.append('ipAddress', ipAddress)
    // formData.append('isCartonPackingMachine', isCartonPackingMachine)
    // formData.append('isActive', isActive)
    // formData.append('locationId', locationId)

    // return Api().post(MACHINE_CONFIG_2, formData)
    return Api().post(MACHINE_CONFIG_2, {
      serialNumber,
      ipAddress,
      isCartonPackingMachine,
      isActive,
      locationId,
    })
  },
}
