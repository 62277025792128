var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"product-details"},[_c('h1',[_vm._v("Machine Status")]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}})],1)],1),(_vm.displayMode === 2)?_c('div',{staticClass:"list-container"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.machineList,"search":_vm.search,"sort-by":"locationName","disable-pagination":"","hide-default-footer":"","fixed-header":"","expanded":_vm.expanded,"item-key":"serialNumber","show-expand":"","height":"500"},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Machine Status")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-combobox',{attrs:{"label":"Location","solo":"","dense":"","hide-details":"true","item-value":"id","item-text":"name","items":_vm.locationItems},model:{value:(_vm.selectedLocation),callback:function ($$v) {_vm.selectedLocation=$$v},expression:"selectedLocation"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.ipAddress",fn:function(ref){
var item = ref.item;
return [(item.isConnected)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-network ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-network")]),_vm._v(" "+_vm._s(item.ipAddress)+" ")]}},{key:"item.sequenceNumber",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-counter")]),_vm._v(" "+_vm._s(item.sequenceNumber)+" ")]}},{key:"item.transactionWeight",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-icon',{staticClass:"px-0 mx-0 mr-2"},[_c('v-icon',[_vm._v("mdi-package-variant-closed")])],1),_c('v-list-item-subtitle',{},[_vm._v(" "+_vm._s(_vm.formatWeight(item.transactionWeight))+" ")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-qrcode")])],1),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_c('a',{attrs:{"href":'/#/product_details2?s=' + item.qrCodeData,"target":"_blank"}},[_vm._v(" "+_vm._s(item.qrCodeData)+" ")])])],1),_c('v-card',{staticClass:"mb-2",attrs:{"max-width":"300","tile":""}},[_c('v-list',{staticClass:"transparent",attrs:{"dense":""}},[_c('v-subheader',[_vm._v("ADDITIONAL DETAILS")]),_c('v-list-item',[_c('v-list-item-title',[_vm._v("Checker No.")]),_c('v-list-item-subtitle',{},[_vm._v(" "+_vm._s(item.checkerNumber)+" ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v("Packer No.")]),_c('v-list-item-subtitle',{},[_vm._v(" "+_vm._s(item.packerNumber)+" ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v("PO Number")]),_c('v-list-item-subtitle',{},[_vm._v(" "+_vm._s(item.productionOfficerNumber)+" ")])],1)],1)],1)],1)]}}],null,false,3231900352)})],1):_vm._e(),(_vm.displayMode === 1)?_c('div',{staticClass:"grid-container"},[_c('v-row',{attrs:{"align":"left"}},_vm._l((_vm.machineList),function(machine){return _c('v-col',{key:machine.serialNumber},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"400","min-width":"300"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5"},[_vm._v(" Machine · "+_vm._s(machine.serialNumber)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"caption"},'span',attrs,false),on),[_vm._v(" (IP - "+_vm._s(machine.ipAddress ? machine.ipAddress : 'n/a')+") ")]),(machine.isConnected)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-network ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-close-network")])]}}],null,true)},[_c('span',[_vm._v(" IP - "+_vm._s(machine.ipAddress)+" ")])])],1),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(machine.transactionTime)+" ")])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-h2",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(machine.sequenceNumber)+" ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-list-item',[_c('v-list-item-icon',{staticClass:"px-0 mx-0 mr-2"},[_c('v-icon',[_vm._v("mdi-package-variant-closed")])],1),_c('v-list-item-subtitle',{},[_vm._v(" "+_vm._s(_vm.formatWeight(machine.transactionWeight))+" ")])],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-qrcode")])],1),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_c('a',{attrs:{"href":'/#/product_details2?s=' + machine.qrCodeData,"target":"_blank"}},[_vm._v(" "+_vm._s(machine.qrCodeData)+" ")])])],1),_c('v-list',{staticClass:"transparent"},_vm._l((machine.qrCodeDataFields),function(item){return _c('v-list-item',{key:item.key},[_c('v-list-item-title',[_vm._v(_vm._s(item.field))]),_c('v-list-item-subtitle',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.data)+" ")])],1)}),1)],1)],1)}),1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }